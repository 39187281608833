import "./harmonization.css";
import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd";
import {useState} from "react";

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
};
const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: '16px 0',
    margin: `0 0 ${grid}px 0`,
    overflow: 'auto',
    background: isDragging ? "#FF4D00" : "white",
    boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px',
    borderRadius: '0.7rem',
    ...draggableStyle
});
const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "rgba(29, 73, 86, 0.9)" : "none",
    border: '1px solid #1d4956',
    borderRadius: '0.2rem',
    padding: grid,
    width: '35%',
    height: '40vh',
    overflowY: 'auto'
});

const Harmonization = ({state, setState, setList}) => {
    const [tooltip, setTooltip] = useState(true)


    function onDragEnd(result) {
        const {source, destination} = result;

        if (!destination) {
            return;
        }
        const sInd = +source.droppableId;
        const dInd = +destination.droppableId;

        const newState = [...state];

        if (sInd === dInd) {
            const items = reorder(state[sInd], source.index, destination.index);
            newState[sInd] = items;
            setState(newState);
        } else {
            const result = move(state[sInd], state[dInd], source, destination);
            newState[sInd] = result[sInd];
            newState[dInd] = result[dInd];

            setState(newState);
        }
        setList(newState[1].map(e => e.content))
    }

    return (
        <>
            <div className='harmonization-header'>
                Select fields to harmonize
                <span style={{color: 'red'}}
                      onMouseOver={() => setTooltip(false)}
                      onMouseLeave={() => setTooltip(true)}> *
                </span>
                <div className='arrow-left' style={tooltip ? {display: "none"} : null}/>
                <div className='tooltip-atom' style={tooltip ? {display: "none"} : null}>
                    At least 2 fields must be selected for harmonization for each dataset, with one of them being the
                    primary key. To harmonize along the primary key field as well, create a duplicate column in the
                    selected sheet and re-upload the Excel file.
                </div>
            </div>

            <div className='harmonization-dgrndrop'>
                <DragDropContext onDragEnd={onDragEnd}>
                    {state.map((el, ind) => (
                        <Droppable key={ind} droppableId={`${ind}`}>
                            {(provided, snapshot) => (
                                <div className="fields-table"
                                     ref={provided.innerRef}
                                     style={getListStyle(snapshot.isDraggingOver)}
                                     {...provided.droppableProps}
                                >
                                    {el.map((item, index) => (
                                        <Draggable
                                            key={item.id}
                                            draggableId={item.id}
                                            index={index}
                                        >
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={getItemStyle(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style
                                                    )}
                                                    className='fields-cell'
                                                >
                                                    <div style={{textAlign:'center'}}>
                                                        {ind === 1 ? index === 0 ? 'Primary Key: ' : `${index}: ` : ''}{item.content}
                                                    </div>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    ))}
                </DragDropContext>
            </div>
        </>
    );
}
export default Harmonization;
