export function processToken() {
    let url = window.location.href.split("token=");
    let token = url[url.length - 1]

    let pattern = /^[A-Za-z0-9-_=]+\.[A-Za-z0-9-_=]+\.?[A-Za-z0-9-_.+=#]*$/;

    if (pattern.test(url[url.length - 1])) {
        if (token[token.length - 1] === "#") {
            token = token.slice(0, -1)
        }

        localStorage.setItem("token", token);

        return token;
    }
    return null;
}
