import env from "react-dotenv";

export const API = (env.REACT_APP_API.endsWith('/')) ? env.REACT_APP_API: env.REACT_APP_API + "/";
export const UI = (env.REACT_APP_UI_URL.endsWith('/') ? env.REACT_APP_UI_URL: env.REACT_APP_UI_URL + "/");

export const API_V1 = 'api/v1/';

export const AUTH_PAGE = env.REACT_APP_AUTH_PAGE;

export const GET_USER = API + 'validate';

export const VALIDATED_RESULTS = API + 'upload';

export const GET_VALIDATED_SHEETS = API + 'validated/sheets'

export const SUBMIT_VALIDATED_DATA = API + 'submit';

export const HARMONIZE = API + 'harmonize';

export const PROGRESS = API + 'progress';

export const DOWNLOAD_RESULTS = API + 'download';