import "./tool.css";
import Process from "./process/Process";
import {useState} from "react";
import Select from "react-select";
import Slider from "rc-slider";
import 'rc-slider/assets/index.css'
import warning from './assets/img/img.png'
import axios from "axios";
import xlsm from './assets/img/xlsm.png'
import download from './assets/img/download.png'
import {DOWNLOAD_RESULTS, HARMONIZE} from "../../utils/urls/urls";
import {showErrorNotification, showNotification} from "../../utils/notifier";

const match_limit_options = [
    {label: 'No Limit', value: 'No Limit'},
    {label: '100', value: '100'},
    {label: '1000', value: '1000'},
    {label: '10000', value: '10000'},
    {label: '100000', value: '100000'}
]

const algorithm_options = [
    {label: 'Levenshtein (equal weights)', value: 'Levenshtein (equal weights)'},
    {label: 'Levenshtein (logarithmic weights)', value: 'Levenshtein (logarithmic weights)'},
    {label: 'Levenshtein Best Score', value: 'Levenshtein Best Score'}
]

const Tool = (props) => {
    const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);
    const [value, setValue] = useState(75);
    const [limit, setLimit] = useState('No Limit');
    const [algorithm, setAlgorithm] = useState('Levenshtein Best Score');
    const [listAFields, setListAFields] = useState([]);
    const [listBFields, setListBFields] = useState([]);
    const [hiddenWarning, setHiddenWarning] = useState(true);
    const [sheetA, setSheetA] = useState();
    const [sheetB, setSheetB] = useState();
    const [warningText, setWarningText] = useState('');
    const [hideFile, setHideFile] = useState(true);
    const [finished, setFinished] = useState(false);
    const [dataMigrationProject, setDataMigrationProject] = useState(false);
    const [disabled, setDisabled] = useState(false);

    const harmonize = () => {
        try {
            if (listAFields.length < 2 || listBFields.length < 2) {
                setWarningText('At least 2 fields must be selected for harmonization for each dataset')
                setHiddenWarning(false)
                return;
            } else if (listAFields.length !== listBFields.length) {
                setWarningText('Number of fields selected to harmonize for both datasets must be the same')
                setHiddenWarning(false)
                return;
            } else if (!dataMigrationProject) {
                setWarningText('Please, select the project')
                setHiddenWarning(false)
                return;
            } else setHiddenWarning(true)

            const body = {};

            body['sheetA'] = sheetA
            body['sheetB'] = sheetB
            body['fieldsA'] = listAFields
            body['fieldsB'] = listBFields
            body['matchScore'] = value
            body['matchLimit'] = limit
            body['harmonizationAlgorithm'] = algorithm
            body['dataMigrationProject'] = dataMigrationProject

            setFinished(false)
            setDisabled(true)

            axios.post(HARMONIZE, {body: body}, {
                headers: {
                    Authorization: localStorage.getItem("token")
                }
            })
                .then(_res => {
                    showNotification('Harmonization ran successfully');
                    setDisabled(false);
                    setFinished(true);
                })
                .catch(err => {
                    showErrorNotification(err?.response?.data?.description ? err?.response?.data?.description : "Something went wrong. Try Again.");
                    setHideFile(true);
                    setDisabled(false);
                });

            setHideFile(false);

        } catch (err) {
            showErrorNotification(err?.response?.data?.description ? err?.response?.data?.description : "Something went wrong. Try Again.");
        }
    }


    const download_file = async () => {
        try {
            const url = await axios.get(DOWNLOAD_RESULTS, {
                headers: {
                    Authorization: localStorage.getItem("token")
                }
            })

            window.open(url.data.url)
        } catch (err) {
            showErrorNotification(err.response.data.description)
        }
    }

    return (
        <div className='tool'>
            <div className="tool-container">
                <Process
                    system={"A"}
                    className='tool-first-section'
                    style={{borderRight: "0.2rem solid lightgrey"}}
                    setList={setListAFields}
                    setSelectedSheet={setSheetA}
                />
                <Process
                    system={"B"}
                    setList={setListBFields}
                    setSelectedSheet={setSheetB}
                />
            </div>

            <p className='warning-text' hidden={hiddenWarning}><img src={warning} alt=""/> {warningText}</p>

            <div className="projects">
                <Select
                    options={[
                        {value: 'Test Project', label: 'Test Project'}]}
                    placeholder="Projects"
                    menuPlacement="auto"
                    menuPosition="fixed"
                    onChange={(option) => setDataMigrationProject(option.value)}
                />
            </div>

            <div className="advanced-options-container">
                <button className="arrow-btn" onClick={() => setShowAdvancedOptions(!showAdvancedOptions)}>
                    <div className={showAdvancedOptions ? 'arrow-up' : 'arrow-down'}/>
                </button>
                <span>Show advanced options</span>

                <div className="advanced-options" style={showAdvancedOptions ? null : {display: 'none'}}>
                    <div className='advanced-options-slider-container'>
                        <span className='advanced-options-slider-header'>Minimum match score: {value}%</span>
                        <Slider
                            style={{width: '70%', display: 'inline-block',}}
                            reverse
                            marks={{100: '70%', 90: '80%', 80: '90%', 70: '100%'}}
                            onChange={v => {
                                setValue(170 - v)
                            }}
                            trackStyle={{background: '#FF5C00'}}
                            railStyle={{background: '#FF5C00', opacity: '0.2'}}
                            step={1}
                            min={70}
                            max={100}
                            defaultValue={95}
                        />
                    </div>

                    <div className="select-section">
                        <div className='select-block'>
                            <span>Positive match limit: </span>
                            <div>
                                <Select
                                    options={match_limit_options}
                                    onChange={(v) => setLimit(v.value)}
                                    defaultValue={{label: 'No Limit', value: 'No Limit'}}
                                    menuPlacement="auto"
                                    menuPosition="fixed"
                                    placeholder="Choose sheet"
                                />
                            </div>
                        </div>

                        <div className='select-block'>
                            <span>Harmonization algorithm: </span>
                            <div>
                                <Select
                                    options={algorithm_options}
                                    onChange={(v) => setAlgorithm(v.value)}
                                    menuPlacement="auto"
                                    menuPosition="fixed"
                                    placeholder="Choose Harmonization Algorithm"
                                    defaultValue={{label: 'Levenshtein Best Score', value: 'Levenshtein Best Score'}}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <button className='harmonize-button' onClick={() => harmonize()} disabled={disabled}
                    style={disabled ? {
                        cursor: 'not-allowed',
                        background: '#203c46'
                    } : {boxShadow: '0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0)'}}>Harmonize Data
            </button>

            {
                !hideFile &&
                <div className='download-excel'>
                    <img src={xlsm} alt="img" height='64px'/>

                    <div>
                        <p>Harmonization Tool Results.xlsm</p>
                        {finished ? 'Done' :
                            <span className="dots">Processing...</span>
                        }
                    </div>

                    <img src={download} alt="icon" height='40px'
                         className={finished ? 'downloadable' : 'blocked'}
                         onClick={finished ? () => {
                             download_file()
                         } : null}
                    />
                </div>
            }
        </div>
    )
}
export default Tool;
