import Drgndrop from "../dashboard/process/drgndrop/Drgndrop";
import "./upload.css";
import Select from "react-select";
import {useState} from "react";
import axios from "axios";
import warning from "../dashboard/assets/img/img.png";
import {showNotification, showErrorNotification} from "../../utils/notifier";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {GET_VALIDATED_SHEETS, SUBMIT_VALIDATED_DATA, VALIDATED_RESULTS} from "../../utils/urls/urls";


const Upload = () => {
    const [sheets, setSheets] = useState([]);
    const [fields, setFields] = useState([]);
    const [selectedFieldA, setSelectedFieldA] = useState(undefined);
    const [selectedFieldB, setSelectedFieldB] = useState(undefined);
    const [selectedSheet, setSelectedSheet] = useState(undefined);
    const [hiddenWarning, setHiddenWarning] = useState(true);
    const [dataMigrationProject, setDataMigrationProject] = useState('Test Project');
    const [disabled, setDisabled] = useState(false);
    const [fileName, setFileName] = useState(null);


    const submit = async () => {
        try {
            setSelectedSheet(null)
            setSelectedFieldA(null)
            setSelectedFieldB(null)

            if (selectedFieldA === selectedFieldB) {
                setHiddenWarning(false)
                return;
            } else if (!selectedFieldA || !selectedFieldB ) {
                setHiddenWarning(false)
                return;
            } else {
                setHiddenWarning(true)
            }

            setDisabled(true)

            let res = await axios.post(SUBMIT_VALIDATED_DATA, {
                    body: {
                        selectedSheet: selectedSheet.value,
                        selectedFieldA: selectedFieldA.value,
                        selectedFieldB: selectedFieldB.value,
                        dataMigrationProject,
                    }
                },
                {
                    headers: {
                        Authorization: localStorage.getItem("token")
                    }
                })

            if (res) {
                showNotification(res);
            }
            setDisabled(false);
        } catch (err) {
            showErrorNotification(err.response.data.description);
            setDisabled(false);
        }
    }

    const getFileData = async () => {
        try {
            setSelectedSheet(null)
            setSelectedFieldA(null)
            setSelectedFieldB(null)

            const res = await axios.get(GET_VALIDATED_SHEETS, {
                headers: {
                    Authorization: localStorage.getItem("token")
                }
            });

            setSheets(res.data);
        } catch (err) {
            showErrorNotification(err.response.data.description);
        }
    }

    const getUploadParams = async (file) => {
        try {
            const body = new FormData();

            body.append('file', file.file)
            body.append('dataMigrationProject', dataMigrationProject)
            setFileName(file.file.name);

            return {url: VALIDATED_RESULTS, body, headers: {Authorization: localStorage.getItem("token")}};

        } catch (err) {
            file.remove();
            setFileName(null);
            showErrorNotification(err);
        }
    }

    return (
        <div className="upload-container">
            <h1 className="upload-header">Upload validated harmonization positive results</h1>

            <Drgndrop
                getUploadParams={(file) => getUploadParams(file)}
                getFileData={getFileData}
            />

            {fileName && <div className='file-name'>{fileName}</div>}

            <div className='select-block-column'>
                <div>
                    <Select
                        options={Object.keys(sheets).map(e => {
                            return {value: e, label: e}
                        })}
                        menuPlacement="auto"
                        menuPosition="fixed"
                        placeholder="Choose sheet with confirmed results"
                        onChange={(option) => {
                            setSelectedSheet({label: option.value, value: option.value})
                            setFields(sheets[option.value])
                            setSelectedFieldA(null)
                            setSelectedFieldB(null)
                        }}
                        value={selectedSheet}
                    />
                </div>

                <div className='select-sub-block'>
                    <div className='select-system-key'>
                        <Select
                            options={fields.map(e => {
                                return {value: e, label: e}
                            })}
                            menuPlacement="auto"
                            menuPosition="fixed"
                            placeholder="System A Pkey"
                            onChange={(option) => {
                                setSelectedFieldA({label: option.value, value: option.value})
                            }}
                            value={selectedFieldA}
                        />
                    </div>

                    <div className='select-system-key'>
                        <Select
                            options={fields.map(e => {
                                return {value: e, label: e}
                            })}
                            menuPlacement="auto"
                            menuPosition="fixed"
                            placeholder="System B Pkey"
                            onChange={(option) => {
                                setSelectedFieldB({label: option.value, value: option.value})
                            }}
                            value={selectedFieldB}
                        />
                    </div>
                </div>
                <div className='select-sub-block'>
                    <span className='select-sub-block-header'>Select data migration project:</span>
                    <div className='select-sub-block-container'>
                        <Select
                            options={[
                                {value: 'Test Project', label: 'Test Project'}]}
                            defaultValue={{value: 'Test Project', label: 'Test Project'}}
                            menuPlacement="auto"
                            menuPosition="fixed"
                            onChange={(option) => setDataMigrationProject(option.value)}
                        />
                    </div>
                </div>
            </div>
            <p className='warning-text' hidden={hiddenWarning}><img src={warning} alt=""/> Primary key fields for system
                A and system B must be different.</p>
            <button className='harmonize-button' onClick={() => submit()} style={disabled ? {
                cursor: 'not-allowed',
                background: '#203c46'
            } : {boxShadow: '0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0)'}}> Submit validated data
            </button>
            <ToastContainer position="top-right"/>
        </div>
    )
}

export default Upload;
