import Select from 'react-select';
import "./selectField.css";

const SelectField = ({sheets, setSelectedSheet, setState, selectedSheet, setSelected}) => {

    return (
        <div className="select-container">
            <Select
                options={Object.keys(sheets).map(e => {
                    return {value: e, label: e}
                })}
                menuPlacement="auto"
                menuPosition="fixed"
                placeholder="Choose sheet"
                onChange={(option) => {
                    setSelectedSheet(option.label)
                    setSelected({label:option.label, value:option.label})
                    setState([sheets[option.label].sort().map(e => {
                        return {id: e, content: e}
                    }), []])
                }}
                value={selectedSheet}
            />
        </div>
    )
}
export default SelectField;
