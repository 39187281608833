import axios from "axios";

import Drgndrop from "./drgndrop/Drgndrop";
import "./process.css";
import Harmonization from "./harmonization/Harmonization";
import SelectField from "./select/SelectField";
import {useState} from "react";
import {API} from "../../../utils/urls/urls";
import {showErrorNotification} from "../../../utils/notifier";


const Process = ({system, style, setList, setSelectedSheet}) => {
    const [sheets, setSheets] = useState({});
    const [state, setState] = useState([[], []]);
    const [selectedSheet, setSelected] = useState();
    const [fileName, setFileName] = useState(null);


    const getUploadParams = (file) => {
        try {
            const body = new FormData();

            body.append('file', file.file)
            body.append('system', system)

            setState([[], []]);
            setSelected(null);
            setFileName(file.file.name);

            return {url: API, body, headers: {Authorization: localStorage.getItem("token")}};

        } catch (err) {
            file.remove();
            setFileName(null);
            showErrorNotification(err);
        }
    }

    const getFileData = async () => {
        try {
            const res = await axios.get(`${API}?system=${system}`, {
                headers: {
                    Authorization: localStorage.getItem("token")
                }
            });

            setSheets(res.data);
        } catch (err) {
            showErrorNotification(err.response.data.description);
        }
    }

    return (
        <div className="process-section" style={style}>
            <h1 className="section-header">Upload ERP System {system} Dataset </h1>

            <Drgndrop
                getUploadParams={(file) => getUploadParams(file)}
                getFileData={getFileData}

            />

            {fileName && <div className='file-name'>{fileName}</div>}

            <div className="harmonization-section">
                <SelectField
                    sheets={sheets}
                    setSelectedSheet={setSelectedSheet}
                    setState={setState}
                    selectedSheet={selectedSheet}
                    setSelected={setSelected}
                />
                <Harmonization state={state} setState={setState} setList={setList}/>
            </div>
        </div>
    )
}
export default Process;
