import {useState, useEffect, useRef} from "react";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import Tool from "./dashboard/Tool";
import History from "./history/History";
import Sidebar from "./sidebar/Sidebar";
import Upload from "./upload/Upload";
import "./mainPage.css";
import {processToken} from "../utils/processToken";
import {redirectToAuth} from "../utils/helpers";
import {validateUser} from "../utils/request";
import {UI} from "../utils/urls/urls";


const MainPage = () => {
    const [page, setPage] = useState('Tool');
    const [isAuthorized, setIsAuthorized] = useState(false);

    const subscription = useRef(true);
    useEffect(() => {
        const jwt = processToken();
        const localStorageToken = localStorage.getItem("token");

        if (!jwt && !localStorageToken) {
            redirectToAuth();
        } else if (jwt) {
            validateUser(jwt)
                .then(_res => window.location = UI)
                .catch(_err => redirectToAuth())
        } else if (localStorageToken) {
            validateUser(localStorageToken)
                .then(_res => setIsAuthorized(true))
                .catch(_err => redirectToAuth())
        }

        return () => subscription.current = false;
    }, [])

    return (
        <>
            {
                (isAuthorized) && (
                    <div className="mainPage">

                        <Sidebar
                            page={page}
                            setPage={setPage}
                        />
                        {
                            page === 'Tool' ?
                                <Tool/> :
                            page === 'Upload' ?
                                <Upload/> : <History/>
                        }
                    </div>
                )
            }
            <ToastContainer
                position="top-right"
                autoClose={7000}
                draggable={false}
                closeOnClick={false}
            />
        </>
        
    )
}
export default MainPage;
