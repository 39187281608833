import axios from "axios";
import {GET_USER} from "./urls/urls";

export const validateUser = async () => {
    const token = localStorage.getItem("token")

    return axios.get(GET_USER, {
        headers: {
            "Authorization": `${token}`
        }
    })
}