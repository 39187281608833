import {getDroppedOrSelectedFiles} from 'html5-file-selector';
import Dropzone from "react-dropzone-uploader";
import React, {useState} from 'react';

import cloud from "./assets/img/cloud.png";
import "./drgndrop.css";
import {redirectToAuth} from "../../../../utils/helpers";
import {showErrorNotification} from "../../../../utils/notifier";


const Drgndrop = ({getUploadParams, getFileData}) => {
    const [value, setValue] = useState(0)

    const getFilesFromEvent = e => {
        return new Promise(resolve => {
            getDroppedOrSelectedFiles(e).then(chosenFiles => {
                resolve(chosenFiles.map(f => f.fileObject))
                setValue(value + 1)

            })
        })
    }

    const InputContent = ({accept, onFiles, files, getFilesFromEvent}) => {
        return (
            <>
                <img alt='img' className="cloud-img" src={cloud}></img>
                <p style={{fontWeight: 'Bold'}}>Drag & Drop file or <label
                    style={{color: '#294956', textDecoration: 'underline', cursor: 'pointer'}}>
                    Browse
                    <input
                        style={{display: 'none'}}
                        type="file"
                        accept={accept}
                        multiple={false}
                        onChange={e => {
                            getFilesFromEvent(e).then(chosenFiles => {
                                onFiles(chosenFiles)
                            })
                        }}
                    />
                </label>
                </p>
                <p style={{color: "gray", fontSize: '15px'}}>Supported formats: XLSX, XLS, XLSM, XLTX, XLTM, ODF, CSV,
                    TXT</p>
            </>
        )
    }

    const handleChangeStatus = ({meta, file, xhr, remove}, status) => {
        if (status === 'headers_received') {
            try {
                getFileData()
                remove()
            } catch(err) {
                remove();
            }

        } else if (status === 'error_upload') {
            if (xhr.status === 401) {
                redirectToAuth()
            } else {
                showErrorNotification("Upload failed")
            }
        }
    }

    return (
        <Dropzone
            accept='.xlsx, .xls, .xlsm, .xltx, .odf, .csv, .txt'
            getUploadParams={getUploadParams}
            onChangeStatus={handleChangeStatus}
            maxFiles={1}
            multiple={false}
            InputComponent={InputContent}
            getFilesFromEvent={getFilesFromEvent}
            styles={{
                inputLabel: {color: '#111'},
                dropzoneActive: {borderColor: 'green'}
            }}
        />
    )
}

export default Drgndrop;
