import logo from "./assets/img.png";
import buttonImg from "./assets/btn-img.png";
import buttonHistoryImg from "./assets/button-history-image.png";
import "./sidebar.css";

const menuList = [
    {
        id: 'Tool',
        name: 'Harmonization Tool',
        img: buttonImg
    },
    {
        id: 'Upload',
        name: 'Upload Validated Results',
        img: buttonImg
    },
    {
        id: 'History',
        name: 'History',
        img: buttonHistoryImg
    }
]

const Sidebar = ({page, setPage}) => {

    return (
        <div className="sidebar">
            <img alt="logo" className="atom-logo" src={logo}></img>

            <ul>
                {menuList.map(btn => {
                    return <li key={btn.id}>
                            <button className='btn' style={page === btn.id ? {color: '#FF4D00'} : null} onClick={(e) => setPage(btn.id)}>
                                <img alt="img" className="btn-icon" src={btn.img}></img>
                                {btn.name}
                            </button>

                            {btn.id === 'Upload' && <hr size="2" color="lightgrey"></hr>}
                    </li>
                })}
            </ul>
        </div>
    )
}

export default Sidebar;
